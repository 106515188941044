body {
  /* font-family:'Inter'  ; */
  background-color: white;
  font-weight: 400;

}
/* p{
 font-family:'Poppins'; 
}
h1{
  font-family:'Poppins';
  font-weight: 500;
  font-style: 'Medium';

} */