.Projects {
  padding: 3rem 1rem;
  background-color: #f7f9fc;
  text-align: center;
}

.Projects-Wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.Projects-Title {
  font-size: 2.5rem;
  font-family: 'Montserrat', sans-serif;
  color: #1a202c;
  margin-bottom: 1rem;
}

.Projects-Description {
  font-size: 1.125rem;
  color: #4a5568;
  max-width: 700px;
  margin: 0 auto 2rem;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
}

.Projects-Grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
}

@media (max-width: 768px) {
  .Projects-Title {
    font-size: 2rem;
  }

  .Projects-Description {
    font-size: 1rem;
  }
}
