/* General styling */
.Projects {
  padding: 3rem 1rem;
  background-color: #f9fafb;
  font-family: "Montserrat", sans-serif;
}

.Projects-Wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.Projects-Heading {
  text-align: center;
  margin-bottom: 2rem;
}

.Projects-Heading h1 {
  font-size: 2.5rem;
  color: #1a202c;
  margin-bottom: 0.5rem;
}

.Project-paragraph {
  font-size: 1.125rem;
  color: #4a5568;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Grid layout for projects */
.Projects-Container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.ProjectCardWrapper {
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

@media (max-width: 768px) {
  .Projects-Heading h1 {
    font-size: 2rem;
  }

  .Project-paragraph {
    font-size: 1rem;
  }
}
