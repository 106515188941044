@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Roboto:wght@300;400;500&display=swap');

:root {
  --primary-bg-color: white;
  --secondary-bg-color: #F5F5F5;
  --main-btn-color: brown;
  --active-btn-color: #EF612B;
  --button-primary: #d36541c4;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #333;
}

h1 {
  font-size: 34px;
  line-height: 1.2;
}

a {
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: inherit;
}

.page-section {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: #fff;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.home, .services, .products, .contact-us, .sign-up, .marketing, .consulting {
  font-size: 100px;
}

.products {
  background-size: fill;
}

.consulting {
  background-position: bottom;
}

@media only screen and (max-width: 320px) {
  h1 {
    font-size: 20px;
    font-weight: 600;
  }
  h2, h4, h5 {
    font-size: 15px;
    font-weight: 500;
  }
  p {
    font-size: 12px;
    line-height: 1.4;
  }
}

@media only screen and (min-width: 768px) {
  h1 {
    font-weight: 500;
    font-size: 30px;
    padding: 30px;
  }
  h2 {
    font-size: 17px;
  }
  h4 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
    line-height: 1.6;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }
}

@media only screen and (min-width: 992px) {
  h1 {
    font-size: 32px;
    padding: 0;
  }
  h2 {
    font-size: 21px;
  }
  h4, h5 {
    font-size: 18px;
  }
  p {
    font-size: 15px;
    line-height: 1.6;
  }
}
