.Hero {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  background: linear-gradient(135deg, #f3f4f6, #e2e8f0);
}

.Hero-Wrapper {
  display: flex;
  max-width: 1200px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
}

.Hero-Left {
  flex: 1;
  color: #1a202c;
  padding-right: 2rem;
}

.Hero-Left h2 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.Hero-Left p {
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.Hero-buttons {
  display: flex;
  gap: 1rem;
}

.button, .nonActiveButton {
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: transform 0.2s;
}

.button {
  background-color: #ef612b;
  color: white;
}

.nonActiveButton {
  background-color: transparent;
  color: #1a202c;
  border: 1px solid #1a202c;
}

.Hero-Right {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
}

.Hero-Logo {
  width: 100%;
  max-width: 350px;
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.Hero-Person {
  width: 100%;
  max-width: 350px;
  position: relative;
}

@media (max-width: 768px) {
  .Hero-Wrapper {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    text-align: center;
  }

  .Hero-Left {
    padding: 0;
  }

  .Hero-Left h2 {
    font-size: 2rem;
  }

  .Hero-Left p {
    font-size: 1rem;
  }

  .Hero-buttons {
    justify-content: center;
  }

  .Hero-Right {
    margin-top: 2rem;
  }

  .Hero-Logo, .Hero-Person {
    max-width: 250px;
  }
}

@media (max-width: 480px) {
  .Hero-Left h2 {
    font-size: 1.5rem;
  }

  .Hero-Left p {
    font-size: 0.9rem;
  }

  .button, .nonActiveButton {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }
}
