.Design-Wrapper {
  text-align: center;
  padding: 50px 20px;
  margin: 0px 10px;
}

.Design-Wrapper p {
  color: #c9cdd7;
  padding: 20px 0;
}

.Design-Work {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;
}

.Logo-Desing {
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  cursor: pointer;
}

.Logo-Desing > img {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .Design-Work {
    padding: 40px 0;
  }

  .Design-Wrapper {
    padding: 50px 30px;
  }

  .Design-Wrapper p {
    padding: 30px;
  }
}

@media only screen and (min-width: 515px) {
  .Design-Wrapper {
    padding: 30px 20px;
  }

  .Design-Work {
    padding: 10px 0 30px;
  }

  .Logo-Desing {
    width: 130px;
    margin: 20px;
  }

  .Logo-Desing > img {
    width: 100%;
  }
}

@media only screen and (min-width: 320px) {
  .Logo-Desing {
    width: 120px;
    margin: 20px;
  }

  .Logo-Desing > img {
    width: 100%;
  }
}
