.WhyChooseUs {
  padding: 60px 20px;
}

.WhyChooseUs-Content-Heading {
  text-align: center;
  margin-bottom: 40px;
}

.WhyChooseUs-Content-Heading p {
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

.WhyChooseUs-Main-Wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.WhyChooseUs-Main {
  background-color: #f4f6fc;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
}

.WhyUsIcon {
  font-size: 3rem;
  color: #0fc0cf;
  margin-bottom: 20px;
}

.WhyChooseUs-Bottom h4 {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.WhyChooseUs-Bottom p {
  font-size: 1rem;
  line-height: 1.6;
}
