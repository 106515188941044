.Contact {
  padding: 3rem 1rem;
  background-color: #f9fafb;
}

.Contact-Wrapper {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.Contact-Header {
  margin-bottom: 1rem;
}

.contact-parag {
  font-size: 1.125rem;
  color: #4a5568;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.Contact-Form-Wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.Contact-Form {
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-parag {
    font-size: 1rem;
    padding: 0 1rem;
  }

  .Contact-Form {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .Contact-Wrapper {
    padding: 0.5rem;
  }

  .Contact-Form {
    padding: 1rem;
  }
}
