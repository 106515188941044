.HowItWorks {
  padding: 3rem 1rem;
  background-color: #f9fbfd;
  text-align: center;
}

.HowItWorks-Wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0px;
}

.HowItWorks-Title {
  font-size: 2.5rem;
  color: #1a202c;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 2rem;
}

.HowItWorks-Content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.HowItWorks-Process {
  background: linear-gradient(135deg, #e0e7ff 0%, #e5ecff 100%);
  border-radius: 12px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.HowItWorks-Process:hover {
  transform: translateY(-5px);
}

.HowItWorks-Icon {
  margin-bottom: 1rem;
}

.HowItWorks-Process-Content h4 {
  font-size: 1.25rem;
  font-family: 'Montserrat', sans-serif;
  color: #333;
  margin-bottom: 0.5rem;
}

.HowItWorks-Process-Content p {
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  color: #666;
  line-height: 1.6;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .HowItWorks-Title {
    font-size: 2rem;
  }

  .HowItWorks-Process-Content h4 {
    font-size: 1.125rem;
  }

  .HowItWorks-Process-Content p {
    font-size: 0.9rem;
  }
}
