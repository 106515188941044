.Home {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  background-color: #f5f5f5;
}

.Home section {
  border-radius: 8px;
  background: linear-gradient(135deg, #ffffff, #f0f0f0);
  transition: transform 0.2s ease;
}

.Home section:hover {
  transform: scale(1.02);
}

@media only screen and (max-width: 768px) {
  .Home {
    padding: 0.5rem;
    gap: 1.5rem;
  }

  .Home section {
    padding: .5rem;
  }
}

