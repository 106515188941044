.WhatWeDo {
  padding: 2rem 0;
  background-color: #f9fafb;
}

.WhatWeDo-Wrapper {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.WhatWeDo-Header {
  margin-bottom: 2rem;
}

.WhatWeDo-Header h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #1a202c;
  margin-bottom: 1rem;
}

.WhatWeDo-Header p {
  font-size: 1.125rem;
  color: #4a5568;
  margin: 0 auto;
  max-width: 700px;
}

.WhatWeDo-Grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
}

.WhatWeDo-Item {
  background-color: #222842;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease;
}

.WhatWeDo-Link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.WhatWeDo-Content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.WhatWeDo-Content h2 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.NextbtnImg {
  width: 24px;
  margin-top: 8px;
}

.WhatWeDo-Logo {
  width: 80%;
  max-width: 200px;
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .WhatWeDo-Header h1 {
    font-size: 2.5rem;
  }

  .WhatWeDo-Header p {
    font-size: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .WhatWeDo-Grid {
    gap: 2rem;
  }

  .WhatWeDo-Item {
    padding: 2rem;
  }

  .WhatWeDo-Content h2 {
    font-size: 1.5rem;
  }

  .NextbtnImg {
    width: 30px;
  }

  .WhatWeDo-Logo {
    max-width: 220px;
  }
}
