.Form {
  width: 100%;
  padding: 20px;
}

.Form-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Form-input input {
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 16px;
  background-color: #fafafa;
  color: #333;
}

.Form-textarea {
  margin-top: 10px;
}

.Form-textarea textarea {
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 16px;
  background-color: #fafafa;
  color: #333;
  resize: vertical;
}

.Contact-Button {
  margin-top: 20px;
  text-align: center;
}

.submit-Btn {
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #ef612b;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.3s ease;
}

.submit-Btn:hover {
  background-color: #d35400;
}
