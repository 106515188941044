.ContactLinks {
  padding: 2rem 1rem;
}

.ContactLinks-Wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.ContactLinks-Wrapper-Content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1.5rem;
}

.ContactLinks-Content {
  background-color: #f4f6fc;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.ContactLinks-Content:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.ContactLinks-Icon {
  font-size: 2.5rem;
  color: #ef612b;
  margin-bottom: 1rem;
}

.ContactLinks-Info h4 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.ContactLinks-Info p {
  font-size: 1rem;
  color: #555;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ContactLinks-Wrapper-Content {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .ContactLinks-Content {
    padding: 1rem;
  }

  .ContactLinks-Icon {
    font-size: 2rem;
  }
}
