/* Base styles for each LogoCard */
.LogoCard {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.LogoCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.Card-Image {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #f4f4f4;
}

.Card-Image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Card-Content {
  padding: 1rem;
  text-align: center;
}

.Card-Content h3 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 0.5rem;
}

.Card-Content p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}

/* Responsive styles */
@media (max-width: 768px) {
  .Card-Image {
    height: 150px;
  }

  .Card-Content h3 {
    font-size: 1.125rem;
  }

  .Card-Content p {
    font-size: 0.875rem;
  }
}
