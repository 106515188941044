/* Base styles for the LogoDesign page */
.LogoDesign {
  padding: 2rem 1rem;
  background-color: #f9fafb;
  text-align: center;
}

.LogoDesign-Header {
  margin-bottom: 2rem;
}

.LogoDesign-Header h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #1a202c;
  margin-bottom: 1rem;
}

.LogoDesign-Header p {
  font-size: 1.125rem;
  color: #4a5568;
  max-width: 700px;
  margin: 0 auto;
}

.LogoDesign-Grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

@media (max-width: 768px) {
  .LogoDesign-Header h1 {
    font-size: 2rem;
  }

  .LogoDesign-Header p {
    font-size: 1rem;
    padding: 0 1rem;
  }
}
