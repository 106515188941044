/* Base styling for Review section */
.Review {
  padding: 3rem 1rem;
  background-color: #f7f9fc;
  text-align: center;
}

.Review-Wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.Review-Title {
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  color: #1a202c;
  margin-bottom: 2rem;
}

/* Responsive grid layout for reviews */
.Review-Content-Grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
}

/* Review card styling */
.Review-Card {
  background: #ffffff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.Review-Card:hover {
  transform: translateY(-5px);
}

/* Review text styling */
.Review-Text {
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  color: #4a5568;
  line-height: 1.6;
  margin-bottom: 1rem;
}

/* Profile section within each review */
.Review-Profile {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.Review-Profile-Image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

.Review-Profile-Details h3 {
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  color: #1a202c;
  margin: 0;
}

.Review-Profile-Details p {
  font-size: 0.875rem;
  font-family: 'Roboto', sans-serif;
  color: #718096;
  margin: 0;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .Review-Title {
    font-size: 1.75rem;
  }

  .Review-Text {
    font-size: 0.95rem;
  }
}
