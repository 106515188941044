.Footer {
    background: linear-gradient(90deg, #1f1f2e, #333366);
  color: #ffffff;
  padding: 2rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.Footer-Wrapper {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.Footer h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #847c7c;

}
.Footer h3 {
  color: #847c7c;

}

.Footer p {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.Footer-Buttons {
  margin: 2rem 0;
}

.FooterActBtn, .FooterNonActBtn {
  background-color: #ef612b;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 0.5rem;
  transition: background-color 0.3s;
}

.FooterNonActBtn {
  background-color: #6c757d;
}

.Footer-Content-Wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 2rem 0;
  text-align: left;
}

.Footer-Section {
  flex: 1;
  min-width: 200px;
  margin: 1rem;
}

.Footer-Section h3 {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.Footer-Section p, .Footer-Section a {
  color: rgba(255, 255, 255, 0.7);
  display: block;
  margin: 0.5rem 0;
  font-size: 0.9rem;
  text-decoration: none;
}

.Footer-Section a:hover {
  color: #ef612b;
  text-decoration: underline;
}

.Footer-SocialIcons a {
  color: #ffffff;
  margin: 0 0.5rem;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.Footer-SocialIcons a:hover {
  color:#ef612b;
}

.Footer-Bottom {
  margin-top: 2rem;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Footer-Bottom a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}

.Footer-Bottom a:hover {
  color: #ef612b;
}

@media (max-width: 768px) {
  .Footer-Content-Wrapper {
    flex-direction: column;
    align-items: center;
  }

  .Footer-Bottom {
    flex-direction: column;
    text-align: center;
  }
}
