.Services {
  width: 100%;
  padding: 60px 20px;
  background-color: #f7f9fc;
}

/* Heading styling */
.Services-Content-Heading {
  text-align: center;
  margin-bottom: 40px;
}

.Services-Content-Heading h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #333;
}

.services-p {
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #555;
}

/* Services grid layout for three columns */
.Services-Top-Content-Wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 40px;
}

/* Service item card styling */
.Services-Contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 12px;
  color: #f5f5f5;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.Services-Contents:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Image styling */
.Services-Top-Img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.Services-Top-Img-Wrapper {
  width: 80px;
  height: 80px;
}

.Services-Top-Img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* Text content styling */
.Services-Bottom h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 10px;
  color: #f5f5f5;
}

.Services-Bottom p {
  font-size: 1rem;
  line-height: 1.5;
  color: #ddd;
  text-align: center;
  margin-bottom: 20px;
}

/* Button styling */
.Services-Button {
  padding: 8px 20px;
  border-radius: 20px;
  border: 2px solid #f5f5f5;
  background-color: transparent;
  font-size: 1rem;
  color: #f5f5f5;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
}

.Services-Button:hover {
  background-color: #f5f5f5;
  color: #222842;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .Services-Top-Content-Wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .Services-Content-Heading h1 {
    font-size: 2rem;
  }

  .services-p {
    font-size: 1rem;
    padding: 0 20px;
  }

  .Services-Button {
    font-size: 0.875rem;
  }

  .Services-Top-Content-Wrapper {
    grid-template-columns: 1fr;
  }
}
