.About {
  padding: 3rem 1rem;
  background-color: #f9fafb;
  font-family: 'Montserrat', sans-serif;
}

.About-Wrapper {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.About-Content-Heading {
  margin-bottom: 2rem;
}

.About-Content-Heading h1 {
  font-size: 2.5rem;
  color: #1a202c;
  margin-bottom: 0.5rem;
}

.About-Content-Heading p {
  font-size: 1.125rem;
  color: #4a5568;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
}

.About-Sections {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
}

.About-Section {
  background: #ffffff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-align: center;
}

.About-Icon {
  font-size: 2.5rem;
  color: #4a90e2;
  margin-bottom: 1rem;
}

.About-Section h4 {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.About-Section p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.About-Team {
  background-color: #006ef1;
  color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
}

.About-Team h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.Team-Member {
  text-align: center;
}

.Member-Details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.Member-Avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ef6130;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.Avatar-Icon {
  color: #ffffff;
  font-size: 1.5rem;
}

.Social-Media {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
}

.Social-Icon {
  font-size: 1.5rem;
  color: #ffffff;
  transition: color 0.3s;
}

.Social-Icon:hover {
  color: #ff7a5a;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
  .About-Content-Heading h1 {
    font-size: 2rem;
  }

  .About-Content-Heading p {
    font-size: 1rem;
  }

  .About-Section h4 {
    font-size: 1.1rem;
  }

  .About-Team h2 {
    font-size: 1.5rem;
  }
}
