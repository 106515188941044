.form-title {

  font-weight: 500;


  text-align: center;
  font-size: 35px;
  padding: 50px 0px 20px;
 
}

@media screen and (min-width: 320px) {
  .form-title {
    /* font-size: 20px; */

}
}

@media screen and (min-width: 414px) {
  .form-title {
    padding: 30px 0px 10px;

  }

}