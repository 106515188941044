.Header {
  background: linear-gradient(90deg, #ef612b, #333366);
  position: sticky;
  top: 0;
  z-index: 200;
  padding: 0.5rem 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Navbar {
display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0px 30px;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.Logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.img-Logo {
  width: 40px;
}

.text-Logo {
  width: 80px;
}

.Navlinks-Wrapper {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.Navbar-links {
  display: flex;
  gap: 1.5rem;
  list-style: none;
}

.Navbar-links a {
  color: #ffffff;
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: color 0.3s ease;
}

.Navbar-links a:hover {
  color: #00bcd4;
}

/* Dropdown styling */
.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333366;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  padding: 10px;
  z-index: 100;
  min-width: 200px;
}

.dropdown:hover .dropdown-menu,
.dropdown .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  margin: 10px 0;
}

.dropdown-menu a {
  color: #f5f5f5;
  font-size: 0.9rem;
  padding: 8px 12px;
  display: block;
  border-radius: 4px;
}

.dropdown-menu a:hover {
  background-color: #0fc0cf;
}

/* Mobile-specific styling */
.menu-toggle {
  display: none;
  background: transparent;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 820px) {
  .Navlinks-Wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, #ef612b, #333366);
    transform: translateY(-100%);
    flex-direction: column;
    padding: 1rem 0;
    visibility: hidden;
    opacity: 0;
  }

  .Navlinks-Wrapper.open {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }

  .Navbar-links {
    flex-direction: column;
    align-items: center;
  }

  .menu-toggle {
    display: inline-block;
  }

  /* Dropdown styling for mobile */
  .dropdown-menu {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background: #333366;
    padding: 0;
  }

  .dropdown-menu li {
    width: 100%;
    margin: 0;
  }
}

