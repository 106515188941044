.News-Letter {
  background-image: url('../../images/Logo.jpg');
}

.newsletter-wrapper {
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletter-content {
  background-color: #499c45bd;
    padding: 40px;
    border-radius: 20px;
}

.newLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.newLogo img {

  width: 120px;
}

.Newletter-header {
  text-align: center;
}

.Newletter-header h1 {
  text-transform: capitalize;
  padding: 20px 0px 10px;
}
.Newletter-header p{
  width: 58%;
    margin: auto;
    padding-bottom: 10px;
}

.news-Btn {
  padding: 10px;
  width: 100%;
  margin: 7px 0px;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 18px;
  cursor: pointer;
  background-color: var(--button-primary);
  color: white;
  display: flex;
  justify-content: center;
}


.news-Btn:hover {
  transition: all 0.3s ease-out;
  background-color: transparent;
  background-color: #499c45;
  ;
  border-radius: 10px;
  border: 2px solid white;
  color: white;
}


.Newsletter-Form {
  padding: 0px !important;
  margin: auto;
  width: 400px;

}

.Form-Input-input {
  padding: 10px;
  font-size: 15px;
  border-radius: 10px;
  border: none;
  background-color: #d6d6d6;
  margin: 10px 0px;
  width: 100%;
}

.Form-Inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 320px) {
    .newLogo img {
    width: 110px;
  }
  .newsletter-content {
    padding: 0px;
}
  .newLogo {
    padding: 15px;
}
 .Newletter-header p {
    width: 100%;
    line-height: 24px;
    padding: 10px;
  }
.Newletter-header h1 {
    font-size: 17px;
    padding: 5px;
}
  .Newsletter-Form {
    width: 100%;
  }
  .Form-Input-input {
    padding: 10px 5px;
    font-size: 13px;
    border-radius: 10px;
    border: none;
    background-color: #d6d6d6;
    margin: 0px 10px 10px;
    width: 100%;
}
.news-Btn > button{
  display: contents !important;
}
  .Form-Inputs label {
    font-size: 15px;
    margin: 10px;
  }
    .newsletter-wrapper {
    margin: 50px 20px;
    height: 100%;
  }
  .Form-Input-Label {
    grid-column-start: 1;
    grid-column-end: 2;

  }
  .news-Btn {
     margin: 20px 0px;
    font-size: 13px;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .Newletter-header p {
    line-height: 24px;
}
}
/* @media only screen and (min-width: 375px) {
  .newLogo {
    padding: 20px;
}
  .newsletter-content {
  padding: 0px;

}
.Newsletter-Form {
    width: 100%
}
.newsletter-wrapper {
    margin: 0px 25px;
}

} */